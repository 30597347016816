import React from 'react';
import linkedinLogo from './../resources/images/linkedin.png'

import { Container, Row, Col, Card, CardBody, CardImg } from "shards-react";


export default function Footer(){
    return(
        <div className="Footer">
        <Row>
            <Col>© Callum McGeeney </Col>
            <Col>
            <div className="Footer-social">
            <a href= "https://www.linkedin.com/in/callum-mcgeeney-a5235b108/" >
            <img src= {linkedinLogo} style={{maxHeight: 30}}/>  
            </a>
            </div>
            </Col>
        </Row>

        </div>

    );
}