import React, { Fragment } from "react";

import { Timeline, Event } from "react-timeline-scribble";

export default class Navigation extends React.Component {

    render() {
        return(
            <div className = "Workexp">
            <Fragment>
            <h1>Work Experience</h1>
            <h2>Professional Work Exerience</h2>

            <div className="timeline" >
            <Timeline>
              <Event interval={"2020-Present"} title={"Rapid7"} subtitle={"Software Engineer I"}>
                Working as a Graduate Software Engineer using technologies such as:
                <ul>
                    <li><b>Languages:</b> Java, Kotlin </li>
                    <li><b>Frameworks/Build tools:</b> Springboot, Maven</li>
                    <li><b>Services:</b> Spinnaker, AWS, Jenkins</li>
                </ul> 
              </Event>
             </Timeline>
            </div>

            <div className="timeline" >
            <Timeline>
              <Event interval={"2017-2018"} title={"Liberty Mutual Insurance (Boston, USA)"} subtitle={"Intern Software Engineer"}>
              Worked under the Predictive Modelling and Innovation team. Converted existing products into microservices. Worked on the innovation to help develop new insurance initiatives. Full Stack development experience using MongoDB, Javascript (ReactJS, NodeJS), HTML, CSS, Bamboo, Jenkins, Springboot, Groovy, Maven and Java (Gosu).
                <ul>Projects:
                    <li><b>Telematics (Car Crash Detection):</b> worked on the creation of a microservice which allowed crashes detected by telematics’ devises in customers’ cars to be filtered into the existing claims process. </li>
                    <li><b>Monitoring Dashboard:</b> worked on the creation of a web application which took statistics from the project management site JIRA and displayed them on one page. Instead of managers having to go and manually collect data from different sources, this app allowed them to have an up to date report at the click of a button.</li>
                </ul>
              </Event>
              <Event interval={"June 2016"} title={"Royal Bank of Canada (London, UK)"} subtitle={"Work Shadowing / Internship "}>
              This was part of the City Scholarship programme 2016. I spent a week work shadowing a number of employees from the Royal Bank of Canada and finding out about the work they do. I talked to people in various positions within the company and carried out some research tasks for the head of European Capital Markets.              </Event>
              </Timeline>
            </div>

            <h2>Other Work Experience</h2>
            <div className="timeline">
            <Timeline>
            <Event interval={"May - September 2019"} title={"43 Bar and Grill (New York, USA)"} subtitle={"Server"}>
              Worked in a busy and fast paced environment serving food and drink to patrons.
              </Event>
            </Timeline>
            </div>
          </Fragment>
          </div>
        )

    }

}