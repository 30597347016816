import React from "react";

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Collapse
} from "shards-react";


import { Alert } from "shards-react";

import { Link, animateScroll as scroll } from "react-scroll";


import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"


export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);

    this.state = {
      dropdownOpen: false,
      collapseOpen: false
    };
  }

  toggleDropdown() {
    this.setState({
      ...this.state,
      ...{
        dropdownOpen: !this.state.dropdownOpen
      }
    });
  }

  toggleNavbar() {
    this.setState({
      ...this.state,
      ...{
        collapseOpen: !this.state.collapseOpen
      }
    });
  }

  render() {
    return (
      <Navbar type="dark" theme="primary" expand="md" sticky="top">

        <NavbarBrand href="#">
          <Link
            activeClass="active"
            to="#top"
            spy={true}
            smooth={true}
            offset={-300}
            duration={500}
            onClick={this.toggleNavbar}
          >
            Callum McGeeney
              </Link>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} />

        <Collapse open={this.state.collapseOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#About"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  About
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#Education"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Education
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#WorkExperience"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Work Experience
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#CommunityService"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Community Service
                </Link>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#Hobbies"
                  spy={true}
                  smooth={true}
                  offset={-340}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Hobbies
              </Link>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink active href="#">
                <Link
                  activeClass="active"
                  to="#Contact"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Get in touch
                </Link>
              </NavLink>
            </NavItem>

          </Nav>

        </Collapse>
      </Navbar>
    );
  }
}