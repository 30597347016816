import React from 'react'

import { Container, Row, Col, } from "shards-react";
import { Card, CardBody, CardTitle, CardSubtitle } from "shards-react";


import ibjjf_boston from './../resources/images/Boston_IBJJF.jpeg'

import kili_top from './../resources/images/kili_top.jpg'



export default class Hobbies extends React.Component {

    render(){

        
        return(
            <div>
            <h1 id="#Hobbies">Hobbies</h1>
            <div className="Hobbies">
                <div className ="cards">
                <Row>
                <div className="BJJ">
                <Col>
                <Card>
                <CardBody>
                    <CardTitle>BJJ</CardTitle>
                    <p> Purple belt in BJJ. Medalled at several Irish, UK and top international competitions including IBJJF, UAEJJF, Grappling Industries and NAGA </p>
                    <img src={ibjjf_boston} />.
                </CardBody>
                </Card>

                </Col>
                </div>
                <div className="Hiking">
                <Col>
                <Card>
                <CardBody>
                    <CardTitle>Hiking</CardTitle>
                    <p> I enjoy hiking around Ireland. In 2015 I climbed Kilimanjaro for the childrens charity childreach.</p>
                    <img src={kili_top} className ="Kili"/>
                </CardBody>
                </Card>

                </Col>
                </div>
                </Row>
                </div>
            </div>
            </div>

        );
    }

}