import React, { Fragment } from "react";

import { Timeline, Event } from "react-timeline-scribble";

export default class CommunityService extends React.Component {

    render() {
        return (

            <div className = "Workexp">
            <Fragment>
            <h1>Community Service</h1>

            <div className="timeline" >
            <Timeline>
              <Event interval={"2015-2016"} title={"Childreach International"} subtitle={"Fundraiser"}>
              Fundraised for the charity Childreach International to raise funds for underprivilaged school children in Tanzania while climbing Mount Kilimanjaro.                
              </Event>
            </Timeline>
            </div>

          </Fragment>
          </div>

        );
    }
}