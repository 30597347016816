import React, { Fragment } from "react";

import { Timeline, Event } from "react-timeline-scribble";

export default class Education extends React.Component {

    render() {
        return(
            <div className = "Workexp">
            <Fragment>
            <h1>Education</h1>
            <h2>University</h2>
            <div className = "timeline">
            <Timeline>
              <Event interval={"2015-2020"} title={"Queens University Belfast"} subtitle={"BEng Computer Science (incl. Professional Experience) | 2.1 Achieved"}>
                <ul>Projects:
                    <li>Dissertation: Used dimensionality reduction techniques and machine learning to visualize and classify cancer types using the python programming language. </li>
                    <li>Important Modules:
                        <ul>
                            <li>Data Structures and Algorithms: Covered key data structures and algorithms in computing and how they work. Covered in C++.</li>
                            <li>Android Project: Year long team based android module developing card game using Java in android studio.</li>
                            <li>Artificial Intelligence and Data analytics: Using AI and Data analytics to identify features and classify data based on identified features. </li>
                        </ul>
                    </li>
                </ul>
              </Event>
              <Event interval={"June 2015"} title={"Abbey CBS Newry"} subtitle={"GCSE/A Level"}>
              <p>A - Level: Geography (A*), Business Studies(A*), ICT(A*)</p>
              <p>AS - Level: History(A)</p>
              </Event>
              
              
            </Timeline>
            </div>
            
          </Fragment>
          </div>
        )

    }

}