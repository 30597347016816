import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navigation from './components/Navigation.js'
import WorkExperience from './sections/WorkExperience.js'
import Education from './sections/Education.js'
import Contact from './sections/Contact.js'
import Footer from './components/Footer.js'
import Hobbies from './sections/Hobbies.js'
import About from './sections/About.js'
import CommunityService from './sections/CommunityService.js'


import { Button } from 'react'

import yosemite_valley from './resources/images/yosemite_valley.jpg'

function App() {

  return (
    <div id="#top">
      <div className="App">
        <Navigation />
        <div className="yosemite_valley">
          <img src={yosemite_valley} className="yosemite_img" />
        </div>
        <div id="#About">
          <About />
        </div>
        <div id="#Education">
          <Education id="#Education" />
        </div>

        <div id="#WorkExperience">
          <WorkExperience />
        </div>


        <div id="#CommunityService">
          <CommunityService />
        </div>

        <div id="#Hobbies">
          <Hobbies />
        </div>

        <div id="#Contact">
          <Contact />
        </div>

      </div>
      <Footer />

    </div>
  );
}

export default App;
