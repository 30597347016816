
import React from 'react'

import { Link, animateScroll as scroll } from "react-scroll";


export default class About extends React.Component {
    render(){
        return (
            <div className="About">
                <p className="Abouttext">
                    Hi my name is Callum McGeeney, I am a 23 year old Computer Scientist from Newry, Northern Ireland. This is my online CV. If you are interested in hiring me or getting freelance work completed then please get in touch, my             <Link
                  activeClass="active"
                  to="#Contact"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
              ><a href="">contact details</a></Link> are at the bottom of the website.
                </p>
            </div>

        );
    }
}